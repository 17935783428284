/*
	Venue by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

var settings = {

    // Images
    images: {
        '/images/slider/2017-02-07/bar.jpg': 'center center',
        '/images/slider/2017-02-07/bar-2.jpg': 'center center',
        '/images/slider/2017-02-07/getraenke.jpg': 'center center',
        '/images/slider/2017-02-07/guinness.jpg': 'center center',
        '/images/slider/2017-02-07/innenraum.jpg': 'center center',
        '/images/slider/2017-02-07/spinnrad-aussen.jpg': 'center center'
    },

    // Transition speed (in ms)
    speed: 2000,

    // Transition delay (in ms)
    delay: 3000

};
