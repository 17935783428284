var LeafletMaps = function (L,d,w) {

    var leafletInstances = [];

    var defaultView = {
        lat: 52.47181,
        lng: 13.31924,
        zoom: 13
    };

    var init = function () {
        $(d).ready(function () {
            var maps = d.querySelectorAll('.map');
            for (var m in maps) {
                if (maps[m] !== undefined && isNaN(m) === false) {
                    var mapElement = maps[m];
                    var mapContainer = d.createElement("div");
                    mapContainer.className = "map-inner-container";
                    mapContainer.id = mapElement.id+"Inner";
                    mapElement.appendChild(mapContainer);
                    renderMapElement(mapContainer, mapElement);
                }
            }
        });
    };

    var renderMapElement = function (mapContainer, mapElement) {
        var id = $(mapContainer).attr("id");
        var lat = $(mapContainer).attr("data-map-lat");
        var lng = $(mapContainer).attr("data-map-lng");
        var zoom = $(mapContainer).attr("data-map-zoom");
        var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        // var osmUrl = '//maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png';
        // var osmUrl = '//b.tile.openstreetmap.org/{z}/{x}/{y}.png';
            var osmAttrib = '<a href="//www.mediawiki.org/wiki/Maps">Wikimedia maps beta</a> | Map data © <a href="//openstreetmap.org/copyright">OpenStreetMap</a> contributors';
        var osmLayer = new L.tileLayer(osmUrl, {attribution: osmAttrib});
        var latlng = false;
        var hasView = false;

        // set up the map
        var map = new L.map(id, {scrollWheelZoom: false});

        // add controls and layer
        map.addLayer(osmLayer);

        // add markers
        var markers = getMarkers(mapElement);
        if (markers.length > 0) {
            var markerGroup = L.featureGroup(markers).addTo(map);
            map.fitBounds(markerGroup.getBounds(), {padding: [15, 15]});
            hasView = true;
        }

        if (!isNaN(lat) && !isNaN(lng)) {
            latlng = L.latLng(lat, lng);
        }

        if (hasView === false) {
            if (!latlng) {
                latlng = L.latLng(defaultView.lat, defaultView.lng);
                zoom = defaultView.zoom;
            }
            setMapPosition(map, latlng, zoom-1);
            w.setTimeout(function() {
                setMapPosition(map, latlng, zoom);
            },500);
        }

        // var resizeTimeout = null;
        // map.on('click focus', function () {
        //    w.clearTimeout(resizeTimeout);
        //    resizeTimeout = w.setTimeout(function() {
        //        map.scrollWheelZoom.enable();
        //        $(mapContainer).css("height", "75vh");
        //        map.invalidateSize();
        //        $(mapContainer).parent().css("height", "75vh");
        //    },0);
        // });
        //
        // map.on('blur', function () {
        //    w.clearTimeout(resizeTimeout);
        //    resizeTimeout = w.setTimeout(function() {
        //        map.scrollWheelZoom.disable();
        //        $(mapContainer).attr("style","");
        //        map.invalidateSize();
        //        $(mapContainer).parent().attr("style","");
        //    },100);
        //
        // });
        // map.on('mouseleave', function () {
        // });

        leafletInstances.push(map);

    };

    var getMarkers = function (mapElement) {
        var markers = [];
        $('[data-type="marker"]', mapElement).each(function () {
            var lat = $(this).attr("data-lat"),
                lng = $(this).attr("data-lng"),
                icon = $(this).attr("data-icon"),
                color = $(this).attr("data-icon-color"),
                iconDef = { icon: "home", markerColor: "blue"},
                markerStyle = {};

            if(icon !== undefined) {
                iconDef = { icon: icon };
            }
            if(iconDef !== undefined && color !== undefined && color !== "") {
                iconDef.markerColor = color;
            }
            if(iconDef !== undefined) {
                markerStyle = {icon: L.AwesomeMarkers.icon(iconDef)};
            }

            markers.push(L.marker(new L.latLng(lat, lng), markerStyle));
        });

        return markers;
    };

    var setMapPosition = function (map, latlng, zoom) {
        map.setView(latlng, zoom);
    };

    this.init = init;
};

$(document).ready(function() {
    var LMaps = new LeafletMaps(L,document,window);
    LMaps.init();
});
