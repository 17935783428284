/*
 Venue by Pixelarity
 pixelarity.com | hello@pixelarity.com
 License: pixelarity.com/license
 */

(function ($) {

    skel.breakpoints({
        normal: '(max-width: 1280px)',
        narrow: '(max-width: 1080px)',
        narrower: '(max-width: 880px)',
        mobile: '(max-width: 768px)'
    });

    // our stuff
    $(function () {

        // localeDates
        $(document).ready(function () {
            $("[data-utc-date]").each(function (i, item) {
                var date = new Date($(item).attr("data-utc-date"));
                console.log(item);
                $(item).html(date.toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }));
            });

            $(document.body).addClass('loaded');
        });

        // GA tracking
        $("a[data-ga-send]").on("click", function () {
            var $this = $(this);
            var gaData = JSON.parse($this.attr("data-ga-send"));
            if (typeof(ga) !== "undefined") {
                try {
                    ga("send", gaData);
                } catch (e) {
                    console.error(e);
                }
            }
        });

        // language switcher
        $("#language-switcher").on("click", "a[class]", function(item) {
            var $this = $(this);
            var cur_lang = $("html").attr("lang");
            var this_path = $this.attr("data-language-path");
            var cur_path = document.location.pathname + document.location.hash;
            if(cur_lang !== "de") {
                cur_path = cur_path.substr(4);
            }
            while(cur_path.substr(0,1) === "/") {
                cur_path = cur_path.substr(1);
            }
            var dest_path = this_path + cur_path;
            $(this).attr("href",dest_path);
            console.log(dest_path);
            // return true;
        })

    });


    // Pixelarity stuff
    $(function () {

        var $window = $(window),
            $body = $('body');

        // Fix: Placeholder polyfill.
        $('form').placeholder();

        // Prioritize "important" elements on mobile.
        skel.on('+mobile -mobile', function () {
            $.prioritize(
                '.important\\28 mobile\\29',
                skel.breakpoint('mobile').active
            );
        });

        // Dropdowns.
        $('#nav > ul').dropotron({
            offsetY: -11,
            noOpenerFade: true,
            alignment: 'center'
        });

        // slider
        var $slider = $('#slider');
        if ($slider.length > 0 && !$slider.hasClass('event') && !$slider.hasClass('shop')) {

            var src = settings.images,
                speed = settings.speed,
                delay = settings.delay,
                zIndex = 2, a = [], i, n, x;

            // Configure target
            //$slider.css('position', 'relative');

            // Build slides and add them to target
            for (i in src) {

                if (!src.hasOwnProperty(i))
                    continue;

                x = $('<div></div>');

                x
                    .css('position', 'absolute')
                    .css('z-index', zIndex - 1)
                    .css('left', 0)
                    .css('top', 0)
                    .css('width', '100%')
                    .css('height', '100%')
                    .css('background-position', src[i])
                    .css('background-image', 'url("' + i + '")')
                    .css('background-size', 'cover')
                    .hide();

                x.appendTo($slider);

                a.push(x);
            }

            // Loop
            i = 0;

            a[i].fadeIn(speed, function () {
                window.setInterval(function () {

                    n = i + 1;
                    if (n >= a.length)
                        n = 0;

                    a[n]
                        .css('z-index', zIndex)
                        .fadeIn(speed, function () {
                            a[i].hide();
                            a[n].css('z-index', zIndex - 1);
                            i = n;
                        });

                }, delay);
            });

            $(document.body).addClass('slider-loaded');
        }

        // Off-Canvas Navigation.

        // Title Bar.
        $(
            '<div id="titleBar">' +
            '<a href="#navPanel" class="toggle"></a>' +
            '</div>'
        )
            .appendTo($body);

        // Navigation Panel.
        $(
            '<div id="navPanel">' +
            '<nav>' +
            '<a href="/" class="link depth-0">Home</a>' +
            $('#nav').navList() +
            '</nav>' +
            '</div>'
        )
            .appendTo($body)
            .panel({
                delay: 500,
                hideOnClick: true,
                hideOnSwipe: true,
                resetScroll: true,
                resetForms: true,
                side: 'left',
                target: $body,
                visibleClass: 'navPanel-visible'
            });

        // Fix: Remove transitions on WP<10 (poor/buggy performance).
        if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
            $('#titleBar, #navPanel, #page-wrapper')
                .css('transition', 'none');

    });

})(jQuery);
